<template>
  <div class="main-page">
    <div class="container">
      <app-bar
        title="Tambah Pasien"
        :previousLink="{ name: 'Checkout' }"
      />
      <div class="col-12 col-md-5">
        <div class="form-patient">
          <div class="group-title mb-4">
            <span class="title-content-package">Tambah Pasien</span> <br>
            <span class="fw-normal fs-12 color-neutral-600">Silahkan lengkapi data diri di bawah ini</span>
          </div>

          <Form @submit="submit" v-slot="{ errors }" enctype="multipart/form-data">
            <label>Nama Pasien</label>
            <div class="input-group mb-3">
              <Field
                type="text"
                v-model="dataRegister.nama"
                name="nama"
                rules="required"
                class="form-control"
                :class="{ 'is-invalid': errors.nama }"
                placeholder="Cth: Para Hita Lestari" />
                <span class="input-group-text bg-grayscale border-0" :class="{ 'is-invalid': errors.nama }">
                <div class="show-password" type="button" @click="dataRegister.nama = ''" :class="{'opacity-0':!dataRegister.nama}">
                  <i class="ri-close-circle-fill fs-24"></i>
                </div>
              </span>
              <!-- <span v-if="!errors.nama" class="invalid-feedback">{{ errorMessage }}</span> -->
              <ErrorMessage name="nama" class="invalid-feedback" />
            </div>

            <label>Alamat Email Pasien</label>
            <div class="input-group mb-3">
              <Field
                type="email"
                v-model="dataRegister.email"
                name="email"
                rules="required"
                class="form-control"
                :class="{ 'is-invalid': errors.email }"
                placeholder="Cth: para.hita@email.com" />
              <span class="input-group-text bg-grayscale border-0" :class="{ 'is-invalid': errors.email }">
                <div class="show-password" type="button" @click="dataRegister.email = ''" :class="{'opacity-0':!dataRegister.email}">
                  <i class="ri-close-circle-fill fs-24"></i>
                </div>
              </span>
              <!-- <span v-if="!errors.email" class="invalid-feedback">{{ errorMessage }}</span> -->
              <ErrorMessage name="email" class="invalid-feedback" />
            </div>

            <label>Tanggal Lahir</label>
            <div class="input-group mb-3 row-flex justify-content-between gap-1">
              <div class="col-3">
                <Field
                  type="number"
                  v-model="date.tgl"
                  name="tgllahir"
                  rules="required"
                  class="form-control"
                  min="1"
                  placeholder="Tanggal"
                />
              </div>
              <div class="col-5">
                <select
                  class="form-select select-month color-neutral-900"
                  id="inputGroupSelect01"
                  v-model="date.bln"
                  name="bulan"
                  rules="required">
                    <option selected disabled value="">Bulan</option>
                    <option value="01">Januari</option>
                    <option value="02">Februari</option>
                    <option value="03">Maret</option>
                    <option value="04">April</option>
                    <option value="05">Mei</option>
                    <option value="06">Juni</option>
                    <option value="07">Juli</option>
                    <option value="08">Agustus</option>
                    <option value="09">September</option>
                    <option value="10">Oktober</option>
                    <option value="11">November</option>
                    <option value="12">Desember</option>
                </select>
              </div>
              <div class="col-3">
                <Field
                  type="number"
                  v-model="date.thn"
                  name="tahun"
                  rules="required"
                  min="1900"
                  class="form-control"
                  placeholder="Tahun"
                />
              </div>
              <ErrorMessage name="tgllahir" class="invalid-feedback" />
            </div>

            <label>No. Telp</label>
            <div class="input-group mb-3">
              <VueTelInput
                v-model="dataRegister.no_hp"
                name="no_hp"
                rules="required"
                mode="international"
                class="form-control"
                :class="{ 'is-invalid': errors.no_hp }"
                :inputOptions="{
                  'placeholder': vueTelOption.placeholder
                }"
                :validCharactersOnly="true"
                @input="handleInputHP"
                @change="handleWa"
              />
              <span class="input-group-text bg-grayscale border-0" :class="{ 'is-invalid': errors.no_hp }">
                <div class="show-password" type="button" @click="dataRegister.no_hp = ''" :class="{'opacity-0':!dataRegister.no_hp}">
                  <i class="ri-close-circle-fill fs-24"></i>
                </div>
              </span>
              <!-- <span v-if="!errors.no_hp" class="invalid-feedback">{{ errorMessage }}</span> -->
              <ErrorMessage name="no_hp" class="invalid-feedback" />
            </div>

            <div v-if="!sameAsWa">
              <label>WhatsApp</label>
              <div class="input-group mb-3">
                <VueTelInput
                  v-model="dataRegister.nowa"
                  name="nowa"
                  rules="required"
                  mode="international"
                  class="form-control"
                  :class="{ 'is-invalid': errors.nowa }"
                  :inputOptions="{
                    'placeholder': vueTelOption.placeholder
                  }"
                  :validCharactersOnly="true"
                  @input="handleInputWA"
                  @change="handleWa"
                />
                <span class="input-group-text bg-grayscale border-0" :class="{ 'is-invalid': errors.nowa }">
                  <div class="show-password" type="button" @click="dataRegister.nowa = ''" :class="{'opacity-0':!dataRegister.nowa}">
                    <i class="ri-close-circle-fill fs-24"></i>
                  </div>
                </span>
                <!-- <span v-if="!errors.nowa" class="invalid-feedback">{{ errorMessage }}</span> -->
                <ErrorMessage name="nowa" class="invalid-feedback" />
              </div>
            </div>

            <div class="input-group mb-2">
              <div class="form-check">
                <input
                  type="checkbox"
                  class="form-check-input"
                  id="exampleCheck1"
                  v-model="sameAsWa"
                  @change="handleWa"
                >
                <label class="form-label pt-1 ms-2 mb-0 fw-400 fs-12 lh-22 color-neutral-700" for="exampleCheck1">No. Telp sama dengan WhatsApp</label>
              </div>
            </div>

            <label class="mb-2">Jenis Kelamin</label>
            <div class="d-flex justify-content-between mb-4">
              <div class="bd-highlight w-50">
                <div class="form-check pe-2">
                  <div
                    @click="dataRegister.jenkelamin = 'L'"
                    class="btn btn-gender d-flex justify-content-between align-items-center rounded-pill"
                    :class="{ active: (dataRegister.jenkelamin === 'L') }"
                  >
                    <p class="fst-normal fw-600 fs-12 lh-16 mb-0">Laki-laki</p>
                    <div
                      class="box-icon rounded-circle d-flex align-items-center justify-content-center"
                      :class="{active: (dataRegister.jenkelamin === 'L') }">
                        <i class="ri-men-line icon-gender"></i>
                    </div>
                  </div>
                </div>
              </div>
              <div class="bd-highlight w-50">
                <div class="form-check ps-2 d-flex justify-content-end">
                  <div
                    @click="dataRegister.jenkelamin = 'P'"
                    class="btn btn-gender d-flex justify-content-between align-items-center rounded-pill"
                    :class="{ active: (dataRegister.jenkelamin === 'P') }"
                  >
                    <p class="fst-normal fw-600 fs-12 lh-16 mb-0">Perempuan</p>
                    <div
                      class="box-icon rounded-circle d-flex align-items-center justify-content-center"
                      :class="{active: (dataRegister.jenkelamin === 'P') }">
                        <i class="ri-women-line icon-gender"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="text-center">
              <span class="text-error fm-nunito fs-12 fw-600">{{ errorMessage }}</span>
              <button
                type="submit"
                class="btn btn-primary w-100 py-2 p-md-2 mt-4 mt-md-0 mb-3"
                :disabled="loadingAddPatient">
                {{ loadingAddPatient ? 'Loading..' : 'Tambah Pasien' }}
              </button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppBar from '@/components/AppBar.vue'
import { ref, reactive } from 'vue'
import { user, registerNewMember } from '@/core/auth'
import { Field, Form, ErrorMessage } from 'vee-validate'
import { VueTelInput } from 'vue-tel-input'
import 'vue-tel-input/dist/vue-tel-input.css'
import { useRouter } from 'vue-router'

export default {
  components: {
    AppBar,
    Field,
    Form,
    ErrorMessage,
    VueTelInput
  },
  setup () {
    const router = useRouter()
    const sameAsWa = ref(false)
    const errorMessage = ref('')
    const loadingAddPatient = ref(false)
    const date = reactive({
      tgl: null,
      bln: '',
      thn: null
    })

    const dataRegister = ref({
      nama: null,
      email: null,
      tgllahir: null,
      no_hp: null,
      nowa: null,
      jenkelamin: null,
      pasien_kode: user.value.pas_id,
      pasien_id: user.value.id
    })

    const handleWa = () => {
      if (sameAsWa.value) {
        dataRegister.value.nowa = dataRegister.value.no_hp
      }
    }

    const handleInputHP = (phone, phoneObject) => {
      if (phoneObject?.formatted) {
        dataRegister.value.no_hp = phoneObject.formatted
      }
    }

    const handleInputWA = (phone, phoneObject) => {
      if (phoneObject?.formatted) {
        dataRegister.value.nowa = phoneObject.formatted
      }
    }

    const submit = async () => {
      try {
        loadingAddPatient.value = true
        // Reset pesan errornya dulu
        errorMessage.value = ''

        const fd = new FormData()
        dataRegister.value.tgllahir = date.thn + '-' + date.bln + '-' + date.tgl

        // Append object ke formData
        Object.keys(dataRegister.value).forEach(key => fd.append(key, dataRegister.value[key]))

        const result = await registerNewMember(fd)
        if (!result.status) {
          errorMessage.value = result.message
          return
        }

        return router.push({ name: 'Checkout' })
      } catch (error) {
        console.error('INI ERROR = ', error)
        errorMessage.value = error.response.data.message
      } finally {
        loadingAddPatient.value = false
      }
    }

    return {
      dataRegister,
      date,
      sameAsWa,
      handleWa,
      errorMessage,
      loadingAddPatient,
      handleInputHP,
      handleInputWA,
      submit
    }
  },
  data () {
    return {
      vueTelOption: {
        placeholder: '8xxx xxxx xxxx'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
select.select-patient {
  height: 100%;
  font-size: 18px;
  line-height: 24px;
  border: none;
  box-shadow: 0px -1px 0px 0px #F2F2F2CC inset;
  background-image: url("../../../../../assets/icon/select-arrow.png");
  background-repeat: no-repeat;
  background-size: 14px auto;
}

.form-control::placeholder {
  color: #CBD5E0;
}

.form-control, .input-group-text {
  background: none;
  border-radius: 0;
  box-shadow: inset 0px -1px 0px rgba(242, 242, 242, 0.8);
  padding: 8px 4px 8px 0px;
  font-size: 18px;
}

.form-control.is-invalid, .input-group-text.is-invalid {
  box-shadow: inset 0px -1px 0px #E21F39;
}

.vue-tel-input {
  border: none;
}

select.select-month {
  height: 100%;
  font-size: 18px;
  line-height: 24px;
  border: none;
  box-shadow: 0px -1px 0px 0px #F2F2F2CC inset;
  background-image: url("../../../../../assets/icon/select-arrow.png");
  background-repeat: no-repeat;
  background-size: 14px auto;
}

.btn-gender {
  padding: 4px 4px 4px 12px;
  border-radius: 8px;
  background: #EFF0F7;
  color: #4E4B66;
  width: 100%;
}

.btn-gender.active {
  background: #3566FC;
  color: #FFFFFF;
}

.box-icon {
  width: 30px;
  height: 30px;
  color: #4A5568;
  background: #FFFFFF;
}

.box-icon.active {
  color: #FFFFFF;
  background: rgba(255, 255, 255, 0.35);
}

input[type="radio"] {
  display: none;
  position: absolute;
}

.form-check {
  padding-left: unset;
}

.form-check .form-check-input {
  margin-left: 0;
}

.input-group>.form-control.form-error {
  background: #FFDDEB;
  color: #14142B;
}

.frame-image {
  position: absolute;
  background: #007a9826;
  width: 56%;
}

.frame-image img {
  width: 90%;
  height: auto;
}

@media (min-width: 360px) and (max-width: 767px) {
  .col-md-5 {
      padding-right: 0px !important;
  }

  .form-login {
      padding-right: 1rem !important;
  }

  .col-md-7 {
      padding-left: 0px;
  }

  .frame-image {
      position: relative;
      background: #007a9826;
      width: 100%;
  }
}
</style>
